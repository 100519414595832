<template>
  <div class="card" @click="handleSetProduct">
    <div class="card-body">
      <div class="icon-text">
        <img :src="this.prd_image_file_path" alt="" class="product-image">
      </div>
      <p class="card-text">
        <span class="stronger">{{ prd_name }}</span><br>
        {{ labelItem }} <span class="stronger">{{ prd_code }}</span><br>
        {{ labelPrice }} ${{ prd_display_price }}<br>
        <span class="out-of-stock" v-if="prd_show_out_of_stock">{{ label_product_not_available }}</span>
      </p>
      <b-button
         @click.stop="handleSetItem"
        variant="primary"
        class="mt-5"
         :disabled="prd_show_out_of_stock"
      >
        {{ btnAddToCart }}
      </b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'card-product-list',
  props: {
    itemId: {
      type: String,
      default: '',
    },
    prd_key: {
      type: String,
      default: '',
    },
    prd_code: {
      type: String,
      default: '',
    },
    prd_image_file_path: {
      type: String,
      default: '/',
    },
    prd_display_price: {
      type: String,
      default: 0,
    },
    prd_name: {
      type: String,
      default: '',
    },
    prd_show_out_of_stock: {
      type: Boolean,
      default: false,
    },
    label_product_not_available: {
      type: String,
      default: 'TESTOut of Stock',
    },
    btnAddToCart: {
      type: String,
      default: 'TESTAdd To Cart',
    },
    labelPrice: {
      type: String,
      default: 'TESTPrice',
    },
    labelItem: {
      type: String,
      default: 'TESTItem',
    },

  },
  data() {
    return {}
  },
  methods: {
    handleSetItem() {
      this.$emit('set_item', {
        itemId: this.itemId,
        prd_key: this.prd_key,
        quantity: 1
      })
    },
    handleSetProduct() {
      this.$emit('set_product', {
        itemId: this.itemId,
        prd_key: this.prd_key
      })
    },
  },
  computed: {
    isAltClass() {
      return this.className === 'altColor'
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.product-image {
 max-width: 270px;
 min-width: 200px;
 @include breakpoint(sm) {
    max-width: 500px;
    min-width: 250px;
  }
}
.altColor {
  background-color: $secondary-color !important;
  &:hover {
    background-color: $primary-color !important;
  }
}
.out-of-stock {
  color: red;
  font-style: italic;
  font-weight: bold;
}
.icon {
  margin: 0 auto;
}
.card {
  justify-content: center;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  width: 100%;
  &:hover {
    border: 1px solid #e9e9e9;
    box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.2) !important;
    cursor: pointer;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .icon-text {
      text-align: center;
    }
    .card-title {
      margin-bottom: 17px;
      margin-top: 15px;
      color: $primary-color;
      font-family: $DIN;
      font-size: 30px;
      letter-spacing: 1px;
      line-height: 44px;
      text-transform: uppercase;
      text-align: center;
    }
    .card-text {
      font-family: "Open Sans", sans-serif, Helvetica, Arial, sans-serif;
      font-size: 1rem;
      text-align: center;
      .stronger {
        font-weight: bold;
      }
      line-height: 30px;
    }
  }
  .border-bot {
    border-bottom: 8px solid $primary-color;
    border-left: none !important;
    border-right: none !important;
    margin-right: none !important;
  }
}
</style>
